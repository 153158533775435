<template>
  <div>
    <base-page-header title="Company Directory" />
    <div class="page-filtration">
      <div
        class="page-filtration__search"
        :class="{ 'page-filtration__search_active': ui.showSearchField }"
      >
        <button
          class="btn page-filtration__button"
          :class="{ 'page-filtration__button_disable': ui.showSearchField }"
          @click="toggleSearchField"
        >
          <img src="@/assets/icons/icon-search.svg" alt="" />
        </button>
        <div class="form-group">
          <label for="searchField">
            <input
              type="text"
              id="searchField"
              class="form-control"
              v-model="query"
              @focusout="hideInput"
              @input="searchEmploye"
              ref="searchField"
            />
          </label>
        </div>
      </div>
      <div class="page-filtration__filters">
        <button
          class="page-filtration__filter"
          v-for="(group, index) in groups"
          :key="group.name + index"
          @click="selectGroup(group)"
          :class="{ 'page-filtration__filter_active': isSelectedFilter(group) }"
        >
          {{ group.name }}
        </button>
      </div>
    </div>
    <div class="form-group me-2 filter-by-state">
      <multiselect
        v-model="selectedState"
        id="fieldState"
        class="full-width"
        track-by="label"
        label="label"
        open-direction="bottom"
        :options="states"
        :close-on-select="true"
        :show-labels="false"
        placeholder="State"
      >
      </multiselect>
    </div>
    <base-page-content>
      <template #content>
        <page-spinner v-if="ui.loading" />
        <div v-else>
          <team-section
            :title="group.name"
            :employees="group.agents"
            v-for="group in groups"
            :key="group.name + group.id"
            v-show="
              group.agents.length > 0 &&
              (ui.selectedFilter.name === 'All' || isSelectedFilter(group))
            "
          />
        </div>
      </template>
    </base-page-content>
  </div>
</template>

<script>
import BasePageContent from "../../components/BasePageContent.vue";
import BasePageHeader from "../../components/BasePageHeader.vue";
import Multiselect from "vue-multiselect";

export default {
  components: {
    Multiselect,
    TeamSection: () => import("./includes/TeamSection.vue"),
    PageSpinner: () => import("../../components/pageSpinner.vue"),
    BasePageHeader,
    BasePageContent,
  },
  name: "TeamDirectory",
  data() {
    return {
      ui: {
        loading: false,
        showSearchField: false,
        selectedFilter: null,
      },
      groups: [],
      savedGroups: [],
      query: "",
      states: [],
      selectedState: null
    };
  },
  beforeMount() {
    this.getPositions();
  },
  methods: {
    getAgents() {
      this.$http
        .get("/api/v1/staff/search", {
          params: {
            isActive: true,
            limit: 200,
          },
        })
        .then((res) => {
          const agents = res.data.agents;
          this.states = res.data.states;
          this.groups = this.groups.map((el) => {
            el.agents = agents.filter((agent) => agent.position === el.name);
            return el;
          });
          this.savedGroups = this.savedGroups.map((el) => {
            el.agents = agents.filter((agent) => agent.position === el.name);
            return el;
          });
          this.ui.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.ui.loading = false;
        });
    },
    getPositions() {
      this.ui.loading = true;
      this.$http
        .get("/api/v1/staff/active")
        .then((res) => {
          const filteredPositions = Object.values(res.data.positions).map(
            (el) => {
              el.agents = [];
              return el;
            }
          );
          const positions = [
            { id: 0, name: "All", agents: [] },
            ...filteredPositions,
          ];
          this.groups = positions;
          this.savedGroups = positions;
          this.ui.selectedFilter = { id: 0, name: "All" };
          this.getAgents();
        })
        .catch((err) => {
          console.log(err);
          this.alertError(err.response.statusText);
        });
    },
    toggleSearchField() {
      this.ui.showSearchField = true;
      this.$refs.searchField.focus();
    },
    hideInput() {
      this.$refs.searchField.blur();
      this.ui.showSearchField = false;
    },
    isSelectedFilter(val) {
      return this.ui.selectedFilter.name === val.name;
    },
    selectGroup(group) {
      this.ui.selectedFilter = group;
      this.searchEmploye();
    },
    searchEmploye() {
      if (this.ui.selectedFilter.name === "All") {
        const saved = JSON.parse(JSON.stringify(this.savedGroups));
        this.groups = saved.map(el => {
          el.agents = el.agents.filter(el => el.fullName.toLowerCase().includes(this.query.toLowerCase()))

          if (this.selectedState && this.selectedState.label !== 'All states') {
            el.agents = el.agents.filter(el => el.licensedStates.includes(this.selectedState.label))
          }

          return el;
        })
      } else {
        const saved = JSON.parse(JSON.stringify(this.savedGroups));
        this.groups = saved.map(el => {
          if (el.name === this.ui.selectedFilter.name) {
            el.agents = el.agents.filter(el => el.fullName.toLowerCase().includes(this.query.toLowerCase()));

            if (this.selectedState && this.selectedState.label !== 'All states') {
              el.agents = el.agents.filter(el => el.licensedStates.includes(this.selectedState.label))
            }
          }
          return el;
        })
      }
    },
  },
  watch: {
    selectedState: {
      handler: function() {
        this.searchEmploye();
      },
      deep: true
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep {
  .multiselect {
    height: 36px;
    width: 200px;
    @media screen and (max-width: 568px) {
      max-width: 50%;
      min-width: 100%;
    }

    &.multiselect--active {
      .multiselect__tags {
        transition: all 0.3s;
        border: 1px solid rgba(38, 111, 229, 0.5);
        box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
      }
    }

    &__tags {
      min-height: 36px;
      max-height: 36px;
      padding: 7px 40px 0 8px;
      transition: all 0.3s;

      &:hover {
        border: 1px solid rgba(38, 111, 229, 0.2);
        box-shadow: 0 0 0 4px rgba(38, 111, 229, 0.1);
      }
    }

    &__select {
      min-height: 36px;
      max-height: 36px;

      &:before {
        top: 13%;
        position: relative;
        right: 0;
        color: #999;
        border: solid rgba(0, 0, 0, 0.46);
        margin-top: 4px;
        border-width: 0px 1px 1px 0;
        display: inline-block;
        padding: 3px;
        content: "";
        transform: rotate(44deg);
      }
    }
    &__placeholder {
      padding-top: 0;
    }
    &__single {
      color: black;
      @media screen and (max-width: 568px) {
        font-size: 9px;
        white-space: nowrap;
      }
    }

    &__option {
      padding: 10px 12px;
      min-height: 36px;
      transition: all 0.3s;
      line-height: 20px;
      font-size: 14px;
      color: #000;
      @media screen and (max-width: 568px) {
        font-size: 9px;
      }

      &--highlight {
        background: #f3f3f3;
        line-height: 20px;
        font-size: 14px;
        color: #000;
        @media screen and (max-width: 568px) {
          font-size: 9px;
        }
      }
    }
  }
}
.page-filtration {
  display: flex;
  padding: 18px 32px;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  margin-bottom: 10px;

  &__button {
    position: relative;
    z-index: 2;
    top: -3px;

    &.page-filtration__button_disable {
      cursor: none;
      pointer-events: none;
    }

    &:active,
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  &__search {
    margin-top: 10px;
    position: relative;
    min-width: 20px;
    margin-right: 10px;

    .form-group {
      position: absolute;
      opacity: 0;
      z-index: -1;
      top: 0;
      width: 0;
      transition: all 0.3s;

      input {
        height: 34px;
        border-radius: 16px;
        padding-left: 35px;
      }
    }

    &_active {
      min-width: 160px;
      .form-group {
        z-index: 0;
        min-width: 160px;
        opacity: 1;
      }
    }
  }

  &__filters {
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 768px) {
      margin-left: 10px;
      padding-right: 20px;
      flex-wrap: nowrap;
      overflow: auto;
    }
  }

  &__filter {
    height: 34px;
    min-width: fit-content;
    border: 1px solid #cccccc;
    border-radius: 16px;
    background: white;
    color: #000000;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
    text-align: center;
    padding: 7px 12px;
    transition: all 0.3s;
    margin: 10px 0 0 6px;

    &.page-filtration__filter_active {
      margin-top: 10px;
      background-color: #000000;
      color: #ffffff;
      padding: 7px 12px;
    }

    &.default {
      padding: 7px 12px;
    }

    span {
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s;
      margin-left: 2px;
      img {
        width: 15px;
      }
    }

    &:hover {
      span {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}
.filter-by-state {
  display: flex;
  padding: 5px 32px 18px 32px;
  flex-wrap: nowrap;
  margin-bottom: 10px;
}
</style>
